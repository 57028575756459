#featureRoadmap {
    color: black;
    height: 100%;
}

.trapezoidBanner {
    z-index: -1;
    position: absolute;
    width: 25%;
    border-left: 30px solid transparent;
    border-right: 30px solid transparent;
    border-top: 45px solid white;
  }