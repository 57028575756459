#quotes {
    height: 150vh;
    width: 100vw;
}

#quotes__panel{
    height: inherit;
    width: inherit;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

#quotes__box{
    margin-top: 10vh;
    border: 2px solid #93c5fd;
    border-radius: 36px;
    transform: translateY(30);
}

.quotes__box {
    transform: translateY(20vh);
    transition: all 2s ease;
}

.quotes__box.reveal {
    transform: translateY(0);
    opacity: 1;
    z-index: 4;
}

.quotes__box.fix{
    position: fixed;
    top: 0vh;
}