#footer {
    position: relative;
    height: 40vh;
    background-color: black;
    border-top: 20px solid rgb(24, 24, 24);
    color: white;
    font-size: 2rem;
    z-index: 12;
}

.footerLinks { 
    height: 100%;
    display: grid;
    grid-template-rows: repeat(auto-fill, 25px);
    grid-auto-flow: column;
    grid-auto-columns: max-content;
    column-gap: 30px;
    justify-content: center;
    padding: 3vh 0;
}

.footerLinks>p{
    margin: 0;
    text-align: left;
}