.slideFromLeft {
    animation: slideFromLeft 0.5s ease-out forwards;
}

@keyframes slideFromLeft {
    0% {
        transform: translateX(-3%);
    }

    50% {
        opacity: 1;
    }

    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

.slideFromRight {
    animation: slideFromRight 0.5s ease-out both;
}

@keyframes slideFromRight {
    0% {
        transform: translateX(3%);
    }

    50% {
        opacity: 1;
    }

    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

.slideFromTop {
    animation: slideFromTop 0.5s ease-out both;
}

@keyframes slideFromTop {
    0% {
        transform: translateY(-5%);
    }

    50% {
        opacity: 1;
    }

    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

.slideFromBottom {
    animation: slideFromBottom 0.5s ease-out both;
}

@keyframes slideFromBottom {
    0% {
        transform: translateY(5%);
    }

    50% {
        opacity: 1;
    }

    100% {
        transform: translateY(0);
        opacity: 1;
    }
}