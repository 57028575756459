#intro {
    background-image: url(../../../../../public/bg3.jpg);
    background-size: auto 300vh;
    height: 300vh;
}

#intro__muteLayer{
    height: 100%;
    background-color: #151515;
    opacity: 0.9;
}