#additionalFeatures{
    position: relative;
    height: 80vh;
    width: 100vw;
}
#additionalFeatures__panel {
    position: absolute;
    margin:  20vh 10vw 0 10vw;
    height: 60vh;
    width: 80vw;
    display: flex;
    justify-content: space-between;
}

#additionalFeatures__mainMessage {
    height: 50%;
    width: 30%;
    display: grid;
    place-items: center;
    background-color: white;
    font-family: Graphik Web Regular;
    font-weight: 1000;
    font-size: calc(10px + 2vmin);
}