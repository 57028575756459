.landing-page {
  text-align: center;
}

.center {
  display: grid;
  place-items: center;
}

.flexRow {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.flexRowBetween {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.flexRowAround{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.flexRowRight {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.landing-page-header {
  position: absolute;
  width: 100%;
  z-index: 1;
  height: 10vh;
  font-size: calc(10px + 2vmin);
}

@font-face {
  font-family: 'Century Gothic';
  src: local('Century Gothic'), url(../../fonts/CenturyGothic.ttf) format('truetype');
}

@font-face {
  font-family: 'Graphik Web Regular';
  src: local('Graphik Web Regular'), url(../../fonts/GraphikWebRegular.ttf) format('truetype');
}