#companyBanners__panel {
    height: 100vh;
    box-shadow: inset 0 0 2em 2em white;
}

#companyBanners__bg {
    position: absolute;
    width: 100vw;
    height: 120vh;
    font-family: Graphik Web Regular;
    font-size: calc(10px + 2vmin);
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    overflow-y: hidden;
    overflow-x: hidden;
}

.companyBanners__logo {
    position: absolute;
    width: 30vw;
    z-index: -1;
}

@keyframes slide {
    0% {
        transform: translateX(-25vw) translateY(-28vh);
    }

    100% {
        transform: translateX(100vw) translateY(0vh);
    }
}

#companyBanners__mainMessage {
    position: absolute;
    margin-top: 5%;
    width: 65%;
    text-align: center;
    font-family: Graphik Web Regular;
    font-weight: 1000;
    font-size: calc(10px + 10vmin);
    background-color: white;
    border: 6px solid black;
    border-radius: 20px;
}

.companyBanners {
    transform: translateY(20vh);
    transition: all 2s ease;
}

.companyBanners.reveal {
    transform: translateY(0);
    opacity: 1;
}

.companyBanners__bottomBorder1 {
    margin-top: 5px;
    height: 195px;
    width: 100vw;
    position: absolute;
}

.companyBanners__bottomBorder1:before,
.companyBanners__bottomBorder1:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    background: transparent;
    /* should be black */
}

.companyBanners__bottomBorder1:after {
    mask:
        radial-gradient(100% 100% at top, transparent 70%, white 70.5%) left,
        radial-gradient(100% 100% at bottom, white 71%, transparent 71.4%) right;
    mask-size: 50% 100%;
    mask-repeat: no-repeat;
    background: #18181b;
}

.companyBanners__bottomBorder2 {
    height: 195px;
    width: 100vw;
    position: absolute;
    z-index: -1;
}

.companyBanners__bottomBorder2:before,
.companyBanners__bottomBorder2:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    background: transparent;
}

.companyBanners__bottomBorder2:after {
    mask:
        radial-gradient(100% 100% at top, transparent 60%, rgba(255,255,255,1) 70.5%, white 71%) left,
        radial-gradient(100% 100% at bottom, white 70.5%, rgba(255,255,255,1) 71%, transparent 83%) right;
    mask-size: 50% 100%;
    mask-repeat: no-repeat;
    background: white;
}